.App {
    text-align: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;


    justify-content: center;
    align-items: center;
    font-family: Arial, sans-serif;
    padding: 50px;

    overflow-x: hidden;
    overflow-y: scroll;
    background: linear-gradient(-45deg, #5cb1dc, #1d98af, #23a6d5, #23d5ab);
    background-size: 400% 400%;
    animation: gradientBG 15s ease infinite;

}

@keyframes gradientBG {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

.App h1 {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-size: 32px;
    color: #444;
    padding: 20px;
}

.App p {
    padding: 15px;
    color: #444;
}

.App .Page {
    max-width: 1040px;
    margin-left: auto;
    margin-right: auto;
}

.App .PageBlock {
    border-top: 1px solid #ddd;
    margin-top: 50px;
    padding: 30px;
    background: #fff;
    opacity: .8;
    border-radius: 10px;
}

.App img {
    max-width: 40px;
    max-height: 40px;
}

.App {
    padding-top: 50px;
}

.NavigationBar {
    background: #fff;
    border-radius: 25px;
    margin: 0;
    opacity: 0.9;
    display: flex;
    padding: 2px 15px;
}

.NavigationBar li {
    display: inline-block;
    padding: 15px 15px;
    cursor: pointer;
}

.NavigationBarGrowItem {
    flex: 1;
    text-align: left !important;
}

.NavigationBarRightItem {
    text-align: right;
}


.NavigationBar img {
    width: 20px !important;
    margin: 0;
    padding: 0;
}

.PageTechnologies {
    max-width: 640px;
    margin-left: auto;
    margin-right: auto;

}

.PageTechnologies img {
    margin: 15px;
    max-width: 60px;
    border-radius: 10px;
}

.PageReference {
    padding: 15px;
    margin-left: auto;
    margin-right: auto;
    max-width: 320px;
    text-align: justify;
    text-justify: auto;
}

.PageReference img {
    border-radius: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    display: block;
}